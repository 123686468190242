import { type Control, type FieldValues, type Path } from "react-hook-form";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/modules/common/components/atoms/form/form";
import { Text } from "~/modules/common/components/atoms/Text";
import {
  ResponseField,
  type ResponseFieldProps,
} from "~/modules/common/components/molecules/ResponseField";
import { cn } from "~/modules/common/components/utils/styles";
import { type ResponseFieldType } from "~/modules/common/interfaces";

type GenericInputProps<T extends FieldValues> = ResponseFieldProps & {
  name?: Path<T>;
  label: string;
  control?: Control<T>;
  isHiddenMode?: boolean;
  placeholder?: string;
  type?: ResponseFieldType;
  disabled?: boolean;
  containerClassName?: string;
  showErrorMessage?: boolean;
  labelClassName?: string;
};

function GenericInput<T extends FieldValues>({
  name,
  label,
  control,
  isHiddenMode,
  placeholder,
  type = "text",
  containerClassName,
  showErrorMessage = true,
  className,
  labelClassName,
  ...inputProps
}: GenericInputProps<T>) {
  return (
    <div className={cn("w-full", containerClassName)}>
      <FormLabel className={labelClassName}>
        <Text size="bodyS" className="text-new-dark-2">
          {label}
        </Text>
      </FormLabel>
      <FormField
        control={control}
        // TODO remove ("" as Path<T>). Dirty way to be able to have optional name and control, in order to display fields just for UI, even if they are not in a react-hook-form
        name={name ?? ("" as Path<T>)}
        render={({ field, fieldState }) => (
          <FormItem>
            <FormControl className="w-full">
              <ResponseField
                {...inputProps}
                {...field}
                className={cn(
                  "h-[50px] w-full rounded-[8px] border border-new-dark-4 px-3 py-2 font-albertsans text-[16px] placeholder:text-new-dark-3 focus-visible:border-new-primary-1",
                  fieldState.error && "!border-new-corail-0",
                  className,
                )}
                isHiddenMode={isHiddenMode}
                placeholder={placeholder}
                type={type}
                dateInputSize="medium"
              />
            </FormControl>
            {showErrorMessage && <FormMessage className="ml-3" />}
          </FormItem>
        )}
      />
    </div>
  );
}

export default GenericInput;
